"use client";

import { useEffect } from "react";
import useAuthStore from "../stores/useAuthStore";
import useCartStore from "../stores/useCartStore";
import axiosInstance from "@/utils/axiosInstance";
import { useIpStore } from "@/stores/useIpStore";

export function AuthWrapper({ children }: { children: React.ReactNode }) {
  const fetchTokenAndUserData = useAuthStore(
    (state) => state.fetchTokenAndUserData
  );
  const getCart = useCartStore((state) => state.getCart);
  const setIpInfo = useIpStore((state) => state.setIpInfo);
  const { userData } = useAuthStore();

  useEffect(() => {
    fetchTokenAndUserData();
  }, [fetchTokenAndUserData]);

  useEffect(() => {
    if (userData?.id) {
      getCart();

      const fetchIpInfo = async () => {
        try {
          const ipInfo = await axiosInstance.post(
            `${process.env.NEXT_PUBLIC_API_URL_BASE}/users/insert-session/${userData?.id}`,
            {},
            { withCredentials: true }
          );

          //console.log(ipInfo);

          setIpInfo(ipInfo.data.userSesion);
        } catch (error) {
          console.error("Error during logout:", error);
          throw new Error("Error during logout");
        }
      };

      fetchIpInfo();
    }
  }, [userData?.id, getCart]);

  return <>{children}</>;
}
