import { create } from "zustand";

interface IpInfo {
  ip: string;
  country_name: string;
  country_code: string;
  city: string;
  region: string;
  time_zone: string;
  calling_code: string;
  currency: string;
  user_agent: string;
}

interface IpStore {
  ipInfo: IpInfo | null;
  setIpInfo: (info: IpInfo) => void;
}

export const useIpStore = create<IpStore>((set) => ({
  ipInfo: null,
  setIpInfo: (info) => set({ ipInfo: info }),
}));
